
export function Loader() {
  return (
    <div className="flex items-start justify-start mx-6 my-8">
  <div
    className="w-12 h-12 ease-linear border-4 border-t-4 border-gray-200 rounded-full loader"
  ></div>
</div>
  )
}
